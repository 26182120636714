import { useEffect, useState } from "react";
import { Col, Container, Form, Row, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../assets/css/home.css";
import close from "../assets/images/close.png";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isNotNullAndNotUndefined } from "../utils/empty-util";
import {
  CAMPAIGN_APPCODE,
  CAMPAIGN_INFO,
  CHECKPHONE_CUSTOM_NAME,
} from "../constants/KeyConstant";
import { crudApi } from "../api/crudApi";
import "../assets/css/checkphone.css";
import { campaignServices } from "../api/Services/CampaignService";
import { CAMPAIGN_INFOR } from "../api/configURL";
import SyncLoader from "react-spinners/SyncLoader";
import { CSSProperties } from "styled-components";
import SetCookie from "../hooks/SetCookie";
import axios from "axios";
import JSEncrypt from "jsencrypt";
import * as CryptoJS from "crypto-js";
import fs from "fs";
import jwt from "jsonwebtoken";
import utf8 from "utf8";
import base64 from "base-64";
import { MetaTags } from "react-meta-tags";

import RemoveCookie from "../hooks/RemoveCookie";
import GetCookie from "../hooks/GetCookie";
import Social from "../components/Social";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  opacity: "0.8",
  background: "white",
  textAlign: "center",
  height: "100vh",
  position: "absolute",
  width: "100%",
  paddingTop: "48vh",
};
// let publicKey = `MIGfMA0ABCDGSIb3DQEBAQUAA4GNADCBiQKBgQCUoxX5qq9w2iZeqeuDnBDujOfp
// 2cf6NFd3ioY7e6cg5wNTyr+wkTLZiIzEd8Uv8zwc+jvcFKeYXVh8dfdP0r/z/puT
// E0m1YAGG+260rthPcqlhpqhRr9ISFN6SeWSfLaV7IYL0NIjesz0f45MwRA/fhKKw
// 1zw0Bvf1U8BMcPPamwIDAQAB`;
let privateKeySigs = `-----BEGIN RSA PRIVATE KEY-----
MIIEpAIBAAKCAQEApEGOK44zPXcMs7UkKlZvX6vI+dPTr5N+9p9C7JJW7gGBY87h
sVPLDdAsXSPktE8I8lKOtMUzXZFi4NwtDM1oGhsY4sd4Rzp54lQhie8Gm66j6Os9
cF5NiBOx4o+4NXAPXVLq/sfLyMr0W3KCohriVeC7xxaLFZRxxMXyoofD8Uiz59qO
JhyPPLWxbzkZTNi86bUZ6sfUQZwcBK8witsWE61XAhXrWXtT/EIHoaO257kAnlGY
Nfkc1+MyIPFJiHL4OwqbE8LCd7eQDFhEiod2O9yH+PsiJT6uPIaT8K1QgGOwCJPe
f3iZlFqg/71renkaQ7aeg/kvTEP29uXNr/YwtQIDAQABAoIBAFVPTv3EL0y/JjO3
4v6yQcoYuJs4r3tDSe/hK6sfHlMjKQajjojBEncsJb6CQ+h6HBVvV3r/6Pux5P8Q
m71IeccFR9mQ7CFid6dbNsgjAvWcsLjqZNCVygLg58TaDDyE5RX9WNvW/TpeXqeY
Q8zZ1a/wttZiGP6uSKCAhtJvXQ9Vc+f9k4DZ6V4Eiku2yBcWNTlI6ua5F6oQL4HW
qNq/lWUrrwoV70gYzFLxc/Gg445mv8wFiJEVbrG0SWrpfzidNIpGqZie0UATkbIU
5nwTpHdL98d8DfptW50CvxUBdHYqGzNdYpVtiIXV98pqcBoeNzN221RxI8iVEvF+
GFAffcECgYEA2aqOVyskHiJkKA5gziejLRC3kvt1YDwh/S6N1ZHKuafbYH0mAVnY
07lzC7SepkvprUfL0BXeXilXr3fc3obXFdtQvsj2QaVoXxBVrWRd9shzSvFnzDxG
lBv3MBvAVXizpRhLb58YBZEguqAqDQgcIzHfpi7AFbOuXZlt1V+sg9kCgYEAwS8C
/nq2LhsxDv6cye96F0oqVfnOcQynODoojiZ48nHMfHCnOISD6t2biKLASKzwv1V1
qtV/AGROJUVIv4bJpf/LR7Zr2/gp9s32SCQdjOgHToromQYcSvdAhiT5qj4D4Gs3
hrocCDeGB8ODWGVKDCGDWHTl4sj307V040iDNj0CgYEAyuZdB06UvW5rlJdKkAGM
7z5NETtL/+gt+teBqVrc8dFSAxDF865wG3UclAXLedL7weZSS+eGwJmjPlLqVZi2
OI6XJzEntKverfcJFrefi6LxLiP4EP8C/59N/c9wq3v3YedSsntIJjBRpQYWDM3J
hNlB1KRxtAkpeeDC7IrhwokCgYEAuvMB1L9wGkJaQYaIuKs13d/6BGXRMHnsQW+W
26nDlewm1a43RWyINPvsjlfh+PLTsHXFf2NImlp3fX4FHsQT1L8sO+P/kUohYiSD
V+ELb9cDazDmU2AkdS+9k5OowOVu3vkjLRBiHFmPLWg5bYvoEsFD2JCX/9Y4bvrF
eUYtJIkCgYA6e8msSJjydJCCGHr2gv1QobviTU4oKefF8OcfPekUC1gEfXd68njd
ZTB7hXRBeyE3sA1cpv8EFMYS18Ly/0DAc6otkZpvYZv5nP1sY3DaPSlahFNQ4GIH
jQmc3DvjAaiJcGbG7S+sDhyfYRavNQeFPjDhJj05tzpIwZZyohdskw==
-----END RSA PRIVATE KEY-----`;
let privateKeySig =
  "MIIEpAIBAAKCAQEApEGOK44zPXcMs7UkKlZvX6vI+dPTr5N+9p9C7JJW7gGBY87hsVPLDdAsXSPktE8I8lKOtMUzXZFi4NwtDM1oGhsY4sd4Rzp54lQhie8Gm66j6Os9cF5NiBOx4o+4NXAPXVLq/sfLyMr0W3KCohriVeC7xxaLFZRxxMXyoofD8Uiz59qOJhyPPLWxbzkZTNi86bUZ6sfUQZwcBK8witsWE61XAhXrWXtT/EIHoaO257kAnlGYNfkc1+MyIPFJiHL4OwqbE8LCd7eQDFhEiod2O9yH+PsiJT6uPIaT8K1QgGOwCJPef3iZlFqg/71renkaQ7aeg/kvTEP29uXNr/YwtQIDAQABAoIBAFVPTv3EL0y/JjO34v6yQcoYuJs4r3tDSe/hK6sfHlMjKQajjojBEncsJb6CQ+h6HBVvV3r/6Pux5P8Qm71IeccFR9mQ7CFid6dbNsgjAvWcsLjqZNCVygLg58TaDDyE5RX9WNvW/TpeXqeYQ8zZ1a/wttZiGP6uSKCAhtJvXQ9Vc+f9k4DZ6V4Eiku2yBcWNTlI6ua5F6oQL4HWqNq/lWUrrwoV70gYzFLxc/Gg445mv8wFiJEVbrG0SWrpfzidNIpGqZie0UATkbIU5nwTpHdL98d8DfptW50CvxUBdHYqGzNdYpVtiIXV98pqcBoeNzN221RxI8iVEvF+GFAffcECgYEA2aqOVyskHiJkKA5gziejLRC3kvt1YDwh/S6N1ZHKuafbYH0mAVnY07lzC7SepkvprUfL0BXeXilXr3fc3obXFdtQvsj2QaVoXxBVrWRd9shzSvFnzDxGlBv3MBvAVXizpRhLb58YBZEguqAqDQgcIzHfpi7AFbOuXZlt1V+sg9kCgYEAwS8C/nq2LhsxDv6cye96F0oqVfnOcQynODoojiZ48nHMfHCnOISD6t2biKLASKzwv1V1qtV/AGROJUVIv4bJpf/LR7Zr2/gp9s32SCQdjOgHToromQYcSvdAhiT5qj4D4Gs3hrocCDeGB8ODWGVKDCGDWHTl4sj307V040iDNj0CgYEAyuZdB06UvW5rlJdKkAGM7z5NETtL/+gt+teBqVrc8dFSAxDF865wG3UclAXLedL7weZSS+eGwJmjPlLqVZi2OI6XJzEntKverfcJFrefi6LxLiP4EP8C/59N/c9wq3v3YedSsntIJjBRpQYWDM3JhNlB1KRxtAkpeeDC7IrhwokCgYEAuvMB1L9wGkJaQYaIuKs13d/6BGXRMHnsQW+W26nDlewm1a43RWyINPvsjlfh+PLTsHXFf2NImlp3fX4FHsQT1L8sO+P/kUohYiSDV+ELb9cDazDmU2AkdS+9k5OowOVu3vkjLRBiHFmPLWg5bYvoEsFD2JCX/9Y4bvrFeUYtJIkCgYA6e8msSJjydJCCGHr2gv1QobviTU4oKefF8OcfPekUC1gEfXd68njdZTB7hXRBeyE3sA1cpv8EFMYS18Ly/0DAc6otkZpvYZv5nP1sY3DaPSlahFNQ4GIHjQmc3DvjAaiJcGbG7S+sDhyfYRavNQeFPjDhJj05tzpIwZZyohdskw==";

const IntroTheTaiLoc = () => {
  /**
   * Secure Hash Algorithm (SHA256)
   * http://www.webtoolkit.info/
   * Original code by Angel Marin, Paul Johnston
   **/

  function SHA256(s) {
    var chrsz = 8;
    var hexcase = 0;

    function safe_add(x, y) {
      var lsw = (x & 0xffff) + (y & 0xffff);
      var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
      return (msw << 16) | (lsw & 0xffff);
    }

    function S(X, n) {
      return (X >>> n) | (X << (32 - n));
    }
    function R(X, n) {
      return X >>> n;
    }
    function Ch(x, y, z) {
      return (x & y) ^ (~x & z);
    }
    function Maj(x, y, z) {
      return (x & y) ^ (x & z) ^ (y & z);
    }
    function Sigma0256(x) {
      return S(x, 2) ^ S(x, 13) ^ S(x, 22);
    }
    function Sigma1256(x) {
      return S(x, 6) ^ S(x, 11) ^ S(x, 25);
    }
    function Gamma0256(x) {
      return S(x, 7) ^ S(x, 18) ^ R(x, 3);
    }
    function Gamma1256(x) {
      return S(x, 17) ^ S(x, 19) ^ R(x, 10);
    }

    function core_sha256(m, l) {
      var K = new Array(
        0x428a2f98,
        0x71374491,
        0xb5c0fbcf,
        0xe9b5dba5,
        0x3956c25b,
        0x59f111f1,
        0x923f82a4,
        0xab1c5ed5,
        0xd807aa98,
        0x12835b01,
        0x243185be,
        0x550c7dc3,
        0x72be5d74,
        0x80deb1fe,
        0x9bdc06a7,
        0xc19bf174,
        0xe49b69c1,
        0xefbe4786,
        0xfc19dc6,
        0x240ca1cc,
        0x2de92c6f,
        0x4a7484aa,
        0x5cb0a9dc,
        0x76f988da,
        0x983e5152,
        0xa831c66d,
        0xb00327c8,
        0xbf597fc7,
        0xc6e00bf3,
        0xd5a79147,
        0x6ca6351,
        0x14292967,
        0x27b70a85,
        0x2e1b2138,
        0x4d2c6dfc,
        0x53380d13,
        0x650a7354,
        0x766a0abb,
        0x81c2c92e,
        0x92722c85,
        0xa2bfe8a1,
        0xa81a664b,
        0xc24b8b70,
        0xc76c51a3,
        0xd192e819,
        0xd6990624,
        0xf40e3585,
        0x106aa070,
        0x19a4c116,
        0x1e376c08,
        0x2748774c,
        0x34b0bcb5,
        0x391c0cb3,
        0x4ed8aa4a,
        0x5b9cca4f,
        0x682e6ff3,
        0x748f82ee,
        0x78a5636f,
        0x84c87814,
        0x8cc70208,
        0x90befffa,
        0xa4506ceb,
        0xbef9a3f7,
        0xc67178f2
      );
      var HASH = new Array(
        0x6a09e667,
        0xbb67ae85,
        0x3c6ef372,
        0xa54ff53a,
        0x510e527f,
        0x9b05688c,
        0x1f83d9ab,
        0x5be0cd19
      );
      var W = new Array(64);
      var a, b, c, d, e, f, g, h, i, j;
      var T1, T2;

      m[l >> 5] |= 0x80 << (24 - (l % 32));
      m[(((l + 64) >> 9) << 4) + 15] = l;

      for (var i: any = 0; i < m.length; i += 16) {
        a = HASH[0];
        b = HASH[1];
        c = HASH[2];
        d = HASH[3];
        e = HASH[4];
        f = HASH[5];
        g = HASH[6];
        h = HASH[7];

        for (var j: any = 0; j < 64; j++) {
          if (j < 16) W[j] = m[j + i];
          else
            W[j] = safe_add(
              safe_add(
                safe_add(Gamma1256(W[j - 2]), W[j - 7]),
                Gamma0256(W[j - 15])
              ),
              W[j - 16]
            );

          T1 = safe_add(
            safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]),
            W[j]
          );
          T2 = safe_add(Sigma0256(a), Maj(a, b, c));

          h = g;
          g = f;
          f = e;
          e = safe_add(d, T1);
          d = c;
          c = b;
          b = a;
          a = safe_add(T1, T2);
        }

        HASH[0] = safe_add(a, HASH[0]);
        HASH[1] = safe_add(b, HASH[1]);
        HASH[2] = safe_add(c, HASH[2]);
        HASH[3] = safe_add(d, HASH[3]);
        HASH[4] = safe_add(e, HASH[4]);
        HASH[5] = safe_add(f, HASH[5]);
        HASH[6] = safe_add(g, HASH[6]);
        HASH[7] = safe_add(h, HASH[7]);
      }
      return HASH;
    }

    function str2binb(str) {
      var bin = Array();
      var mask = (1 << chrsz) - 1;
      for (var i = 0; i < str.length * chrsz; i += chrsz) {
        bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - (i % 32));
      }
      return bin;
    }

    function Utf8Encode(string) {
      string = string.replace(/\r\n/g, "\n");
      var utftext = "";

      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }

      return utftext;
    }

    function binb2hex(binarray) {
      var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
      var str = "";
      for (var i = 0; i < binarray.length * 4; i++) {
        str +=
          hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8 + 4)) & 0xf) +
          hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8)) & 0xf);
      }
      return str;
    }

    s = Utf8Encode(s);
    return binb2hex(core_sha256(str2binb(s), s.length * chrsz));
  }

  // function encryptMessage(message: string, publicKey: string) {
  //   const crypt = new JSEncrypt();
  //   crypt.setPrivateKey(privateKey);
  //   const sign = crypt.sign("taipham", CryptoJS.SHA256, "s");
  //   console.log(sign);
  //   // FE
  //   const jsEncrypt = new JSEncrypt();
  //   jsEncrypt.setPublicKey(publicKey);
  //   let a = jsEncrypt.encrypt("taipham");
  //   //Node
  //   let test = jsEncrypt.verify("taipham", sign as string, CryptoJS.SHA256);
  //   console.log(test);
  //   console.log(a);

  //   // return jsEncrypt.encrypt(message);
  //   // BE
  //   let decrypt = new JSEncrypt();
  //   decrypt.setPrivateKey(privateKey);
  //   var uncrypted = decrypt.decrypt(a as string);
  //   console.log(uncrypted);
  // }

  const [isActive2, setisActive2] = useState(false);
  const [isActive3, setisActive3] = useState(true);
  const [loading, setloading] = useState<boolean>(true);
  const [showmore, setshowmore] = useState(false);
  const [customer_name, setcustomer_name] = useState("");
  const [errRegister, setErrRegister] = useState("");
  const [popupMessage, setpopupMessage] = useState("");
  const [openPopupMessage, setopenPopupMessage] = useState<boolean>(false);
  const [isTruePhone, setisTruePhone] = useState<boolean>(false);
  const [phone, setphone] = useState("");
  const [popupMessageValidate, setpopupMessageValidate] = useState("");
  const [redirectHomePage, setredirectHomePage] = useState(false);
  const [code_gift, setCode_gift] = useState("");
  const [color, setColor] = useState("red");
  const [checkCountry, setCheckCountry] = useState("");
  const navigate = useNavigate();
  const appCode = localStorage.getItem(CAMPAIGN_APPCODE);
  let cpData: any = JSON.parse(localStorage.getItem(CAMPAIGN_INFO) || "{}");
  useEffect(() => {
    console.log(appCode);
    window.scrollTo(0, 0);

    // encryptMessage("taipham", publicKey);

    // const a = CryptoJS.SHA256("taipham", publicKey);
    // console.log(a);
    const datatest = `{
      "params":{
          "url": "https://tailoctest.mvc.com.vn/pgtheloc"
   }
  }`;
    const ij = SHA256(datatest);
    console.log(ij);
    const ab = CryptoJS.HmacSHA256(
      `{
        "params":{
            "url": "https://tailoctest.mvc.com.vn/pgtheloc"
     }
    }
  `,
      privateKeySig
    );
    console.log(ab);

    let encoded = base64.encode(
      "f2f30a6a4a21f8cbf143c3c2560c2e627b3ec5c1f9da000bbb2e8c4d039fa0fb"
    );
    console.log(encoded);
    let bytes = utf8.encode(encoded);
    const datatest1 = {
      params: {
        url: "https//:tailoctest.mvc.com.vn/pgtheloc",
      },
    };

    const ordered = Object.keys(datatest1)
      .sort()
      .reduce((obj, key) => {
        obj[key] = datatest1[key];
        return obj;
      }, {});
    console.log(ordered);

    const convert_string = JSON.stringify(ordered);
    console.log(convert_string);
    const string_utf8 = StringToUTF8(convert_string);
    console.log(string_utf8);

    let sign = new JSEncrypt();
    sign.setPrivateKey(privateKeySig);
    let signature = sign.sign(convert_string, CryptoJS.SHA256, "sha256");
    // let encod = utf8.decode(signature);
    console.log(signature);
    // console.log(encod);
    // getIP();
    // const result = privateSign(privateKeySig, datatest);
    // console.log(result);
  }, []);

  function StringToUTF8(text: any) {
    var surrogate = encodeURIComponent(text);
    var result = "";
    for (var i = 0; i < surrogate.length; ) {
      var character = surrogate[i];
      i += 1;
      if (character == "%") {
        var hex = surrogate.substring(i, (i += 2));
        if (hex) {
          result += String.fromCharCode(parseInt(hex, 16));
        }
      } else {
        result += character;
      }
    }
    return result;
  }
  const [dataCheckIP, setCheckIP] = useState<any>(undefined);
  const [dataCampaign, setDataCampaign] = useState<any>();
  console.log(dataCheckIP);
  useEffect(() => {
    // if (dataCheckIP !== undefined) {
    setloading(true);
    let url = "";
    // if (appCode === "pgtheloc") {
    //   url = "https://scratchcard.mvc.com.vn/" + appCode;
    // } else {
    url = "https://scratchcard.mvc.com.vn/" + appCode;
    // }

    const data = {
      params: { url: url },
    };
    console.log(data);
    const a = GetCookie("IP");
    console.log(typeof a);
    campaignServices
      .getCampaignInfo(data)
      .then((response: any) => {
        console.log(response);
        setDataCampaign(response);
        localStorage.setItem(CAMPAIGN_INFOR, JSON.stringify(response));
        cpData = response;
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setpopupMessage(e);
        setopenPopupMessage(true);
        // navigate("/notfound");
      });
    // }
  }, []);
  // function privateSign(key, data, outputEncoding = "base64" as any) {
  //   const signer = createSign("SHA256");

  //   return signer.update(data).sign(key.toPem(), outputEncoding);
  // }
  const getIP = async () => {
    RemoveCookie("IP");

    const a = await axios.get(
      "https://ipgeolocation.abstractapi.com/v1/?api_key=10c186258733484e812b16506581652d"
    );
    console.log(a);
    if (a?.data?.country === "Vietnam") {
      setCheckIP(a?.data.ip_address);
      SetCookie("IP", a.data.ip_address);
    } else {
      navigate("/page403");
    }
  };

  const onCheckBox2 = () => {
    setisActive2(!isActive2);
  };
  const onCheckBox3 = () => {
    setisActive3(!isActive3);
  };
  const showMore = () => {
    setshowmore(!showmore);
  };
  const handleLink = () => {
    window.open("https://www.pg.com/privacy/english/privacy_statement.shtml", "WindowName", "noopener");
  };
  const checkRegister = () => {
    setErrRegister(`Vui lòng ấn xem thêm và bấm “Tôi đồng ý” để tiếp tục`);
  };
  const onRegister = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const check = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
    if (
      isNotNullAndNotUndefined(code_gift) &&
      isNotNullAndNotUndefined(customer_name) &&
      isNotNullAndNotUndefined(phone) &&
      phone.length === 10
    ) {
      // navigate("/wheelresult");

      const a = check.test(phone);
      if (check.test(phone) === false) {
        setpopupMessageValidate("Vui lòng nhập đúng định dạng số điện thoại");
        setopenPopupMessage(true);
        setphone("");
      } else {
        setisTruePhone(true);
        setopenPopupMessage(true);
      }
    } else {
      setpopupMessage(
        "Vui lòng nhập đầy đủ thông tin Tên khách hàng, số điện thoại và mã dự thưởng!"
      );
      setopenPopupMessage(true);
      setredirectHomePage(false);
      setloading(false);
    }
  };
  const closePopupValidate = () => {
    setopenPopupMessage(false);
    setpopupMessageValidate("");
  };
  const closePopup = () => {
    setopenPopupMessage(false);
    setisTruePhone(false);
    navigate("/" + appCode);
  };

  const btnAcceptSubmit = (e: any) => {
    localStorage.setItem("phone", phone);
    setopenPopupMessage(false);
    setisTruePhone(false);
    // setloading(true);
    const data = {
      params: {
        customer_name: customer_name,
        phone: phone,
        url: "https://scratchcard.mvc.com.vn/" + appCode,
        code: code_gift,
      },
    };

    campaignServices
      .checkPhone(data)
      .then((response: any) => {
        console.log(response);
        SetCookie("user", JSON.stringify(data));
        localStorage.setItem("voucher_bigc", JSON.stringify(response));
        navigate("/" + appCode + "/wheelresult");
      })
      .catch((err): any => {
        console.log(err);
        setopenPopupMessage(true);
        setpopupMessage(err);
      });
  };

  const validateData = (value: string, key: string) => {
    const check = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
    if (key === "phone" && value.length !== 10) {
      if (check.test(value)) {
        setpopupMessageValidate("Vui lòng nhập đúng định dạng số điện thoại");
      } else {
        setpopupMessageValidate(
          "Vui lòng nhập số điện thoại đúng định dạng 10 số!"
        );
      }

      setopenPopupMessage(true);
      setphone("");
    }
  };
  const handleInputPhone = (e: any) => {
    setphone(e.target.value);
  };
  const handleInputCode = (e: any) => {
    setCode_gift(e.target.value);
  };

  const redirectToPrizes = () => {
    window.open(
      "https://scratchcard.mvc.com.vn/" + dataCampaign.tc_url,
      "noopener"
    );
  };
  return (
    <>
      <MetaTags>
        <title>{cpData?.name}</title>
      </MetaTags>
      {loading ? (
        <SyncLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div className={""}>
          <div style={{ position: "relative", top: 0 }}>
            <img
              src={cpData?.logo_image_header}
              className="image-home"
              style={{ position: "relative", top: 0, zIndex: "100" }}
            />
            <img
              src={cpData?.background_img}
              className=""
              style={{
                width:"100%",
                position: "relative",
                marginTop: "-140px",
                zIndex: "50",
              }}
            />
            <div style={{ position: "absolute", top: "26vh", zIndex: "150", color: cpData?.home_text_color, }}>
              <div
                style={{
                  padding: "0 3px",
                  textAlign: "center",
                }}
              >
                Vui lòng cung cấp thêm thông tin chính xác để nhận quà.
              </div>
              <Container fluid="md" style={{ paddingTop: "3px" }}>
                <Form style={{ marginTop: "10px" }}>
                  <Row
                    style={{
                      marginTop: "5px",
                      width: "100vw",
                      padding: "0 15px",
                    }}
                  >
                    <Col xs={24} md={24}>
                      <input
                        className="input-form"
                        placeholder="Nhập tên khách hàng"
                        onChange={(e) => setcustomer_name(e.target.value)}
                        value={customer_name}
                      ></input>
                    </Col>
                  </Row>

                  <Row
                    style={{
                      marginTop: "10px",
                      width: "100vw",
                      padding: "0 15px",
                    }}
                  >
                    <Col xs={24} md={24}>
                      <input
                        className="input-form"
                        type="tel"
                        placeholder="Nhập số điện thoại"
                        onChange={(e) => handleInputPhone(e)}
                        onBlur={(e) => validateData(e.target.value, "phone")}
                        value={phone}
                      ></input>
                    </Col>
                  </Row>

                  <Row
                    style={{
                      marginTop: "10px",
                      width: "100vw",
                      padding: "0 15px",
                    }}
                  >
                    <Col xs={24} md={24}>
                      <input
                        className="input-form"
                        placeholder="Nhập mã dự thưởng"
                        onChange={(e) => handleInputCode(e)}
                        value={code_gift}
                      ></input>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      color: `${
                        appCode === "pgtheloc" ? "#F6E192" : "#f63440"
                      }`,
                      display: "flex",
                      justifyContent: "center",
                      padding: "0 40px",
                    }}
                  >
                    {errRegister}
                  </Row>
                </Form>
              </Container>
              <div style={{ marginTop: "15px", padding: "0 10px", color: cpData?.home_text_color, width: "100%" }}>
                <div  className="title-checkbox" >
                  <div className="confirm"  style={{ marginTop: "15px", padding: "0 0px", color: cpData?.home_text_color }}>
                    <span style={{ color: cpData?.home_text_color }}>
                      <div className="containerNotify__backgrounds">
                        <div className="containerNotify__backgrounds-lis">
                          <div className="checkbox-tc">
                            <input
                              type="checkbox"
                              className="input-checkbox  "
                              placeholder="Nhập mã OTP"
                              id="label2"
                              onClick={(e) => onCheckBox2()}
                            ></input>

                            <label className="label-checkbox1" htmlFor="label2" style={{fontSize:"18px", fontWeight:"700"}}>
                              Tôi đồng ý{" "}
                            </label>
                          </div>
                          <div style={{ marginTop: "8px" }}>
                            Là một phần của chương trình này, P&G sẽ chia sẻ dữ
                            liệu cá nhân của bạn với đơn vị cung cấp dịch vụ
                            được P&G ủy quyền, các công ty thuộc tập đoàn P&G,
                            và/hoặc chuyển dữ liệu cá nhân của bạn đến một địa
                            điểm bên ngoài Việt Nam cho các mục đích xử lý được
                            mô tả bên dưới. P&G không bán dữ liệu cá nhân của
                            bạn cho bên thứ ba.
                            <br />
                            <div style={{ marginTop: "8px" }}>
                              {" "}
                              Bạn quyết định việc đánh dấu vào các ô bên dưới và
                              đồng ý cho chúng tôi sử dụng dữ liệu cá nhân của
                              bạn. Tuy nhiên, lựa chọn từ chối của bạn có thể
                              ảnh hưởng đến việc nhận sản phẩm/dịch vụ mà chúng
                              tôi cung cấp theo chương trình, cũng như giới hạn
                              trải nghiệm mà bạn có được khi tham gia chương
                              trình này.
                            </div>
                          </div>
                          <div style={{ marginTop: "8px" }}>
                            • P&G Việt Nam và đơn vị cung cấp dịch vụ của P&G có
                            thể xử lý dữ liệu cá nhân của tôi nhằm mục đích đánh
                            giá điều kiện tôi tham chương trình khuyến mại, liên
                            hệ trao giải thưởng, quản lý và báo cáo kết quả của
                            chương trình theo quy định của pháp luật. Nếu bạn từ
                            chối đồng ý, bạn sẽ không thể tham gia chương trình
                            này.
                          </div>

                          <div style={{ marginTop: "10px" }}>
                            Chính sách bảo mật của chúng tôi có sẵn tại{" "}
                            <span
                              className="wrap"
                              onClick={handleLink}
                              style={{
                                color: `${cpData?.home_url_color}`,
                              }}
                            >
                             https://www.pg.com/privacy/english /privacy_statement.shtml
                            </span>{" "}
                            và áp dụng cho việc thu thập, sử dụng và tiết lộ dữ
                            liệu cá nhân của bạn. Bằng cách đánh dấu vào các ô ở
                            trên, bạn đồng ý cho P&G được thu thập, sử dụng, xử
                            lý và chuyển dữ liệu cá nhân của bạn theo Chính sách
                            quyền riêng tư của chúng tôi.
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <Row
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  padding: "0 40px",
                }}
              >
                <Col>
                  {isActive2 && isActive3 === true ? (
                    <button
                      className={"btn-register-active"}
                      onClick={(e) => onRegister(e)}
                      disabled={!isActive2 || loading || !isActive3}
                    >
                      TIẾP TỤC
                    </button>
                  ) : (
                    <div
                      className="btn-register"
                      onClick={() => checkRegister()}
                    >
                      TIẾP TỤC
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>

          <Modal show={openPopupMessage}>
            <div className="popup-box">
              {isTruePhone ? (
                <>
                  <img className="close-btn" src={close} onClick={closePopup} />
                  <div className="popup-message">
                    <div style={{ marginTop: "10px" }} className="popup-phone">
                      {phone}
                    </div>
                    <p>
                      Có phải số điện thoại chính xác của bạn không? Chúng tôi
                      sẽ gửi voucher đến số điện thoại này.
                    </p>
                  </div>
                  <button
                    className="popup-btn-ok"
                    onClick={(e) => btnAcceptSubmit(e)}
                  >
                    Đồng ý
                  </button>
                </>
              ) : popupMessageValidate ? (
                <>
                  <img
                    className="close-btn"
                    src={close}
                    onClick={closePopupValidate}
                  />
                  <div className="popup-message">
                    <h3 className="popup-header">Thông báo</h3>
                    <p>{popupMessageValidate}</p>
                  </div>
                  <button className="popup-btn-ok" onClick={closePopupValidate}>
                    Đồng ý
                  </button>
                </>
              ) : (
                <>
                  <img className="close-btn" src={close} onClick={closePopup} />
                  <div className="popup-message">
                    <h3 className="popup-header">Thông báo</h3>
                    <p>{popupMessage}</p>
                  </div>
                  <button className="popup-btn-ok" onClick={closePopup}>
                    Đồng ý
                  </button>
                </>
              )}
            </div>
          </Modal>
          <Social />
        </div>
      )}
    </>
  );
};

export default IntroTheTaiLoc;

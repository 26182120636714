import "../assets/css/wheelresult.css";
import INTRO_2 from "../assets/images/intro-first.png";
import FOOTER_1 from "../assets/images/footer-1.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/wheelresult.css";
import GIFT from "../assets/images/gift.png";
import GIFTS from "../assets/images/bg-giftss.png";
import TITLE from "../assets/images/title-guide.png";
import { Col, Modal, Row } from "react-bootstrap";
import BG_POPUP from "../assets/images/bg-popup.png";
import TITLE_POPUP from "../assets/images/title-popup.png";
import INPUT_CUSTOM from "../assets/images/input-custom-img.png";
import CLOSE_WR from "../assets/images/close.png";
import { CAMPAIGN_APPCODE, CAMPAIGN_INFO } from "../constants/KeyConstant";
import { crudApi } from "../api/crudApi";
import GetCookie from "../hooks/GetCookie";
import { campaignServices } from "../api/Services/CampaignService";
import SetCookie from "../hooks/SetCookie";
import close from "../assets/images/close.png";
import axios from "axios";
import Social from "../components/Social";
import SyncLoader from "react-spinners/SyncLoader";
import { CSSProperties } from "styled-components";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  opacity: "0.8",
  background: "white",
  textAlign: "center",
  height: "100vh",
  position: "absolute",
  width: "100%",
  paddingTop: "48vh",
};

const Home = () => {
  const appCode = localStorage.getItem(CAMPAIGN_APPCODE);
  let cpData: any = JSON.parse(localStorage.getItem(CAMPAIGN_INFO) || "{}");
  const [seeMore, setSeeMore] = useState(false);
  const [popupCode, setPopupCode] = useState(false);
  let voucher: any = JSON.parse(localStorage.getItem("voucher_bigc") || "{}");
  const [gift, setGift] = useState<any>(voucher);
  const [loading, setloading] = useState<boolean>(true);
  const [popupMessage, setpopupMessage] = useState("");
  const [color, setColor] = useState("red");
  const [openPopupMessage, setopenPopupMessage] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleOpen = () => {
    setSeeMore(!seeMore);
    setSeeMore(true);
  };
  const onRegister = () => {
    setCode("");
    setPopupCode(true);
  };
  const [code, setCode] = useState("");
  const onInputCode = (e: any) => {
    console.log(e.target.value);
    setCode(e.target.value);
  };
  const closePopup = () => {
    setPopupCode(false);
    setSeeMore(false);
  };
  const closePopupGiftError = () => {
    setopenPopupMessage(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // if (appCode !== "loc" && appCode !== "tai") {
    //   navigate("/notfound");
    // }
    // getIP();
    setloading(true);
  }, []);
  useEffect(() => {
    setloading(false);
  }, [gift]);

  const getIP = async () => {
    const a = await axios.get(
      "https://ipgeolocation.abstractapi.com/v1/?api_key=10c186258733484e812b16506581652d"
    );
    if (a?.data?.country === "Vietnam") {
    } else {
      navigate("/page403");
    }
  };
  const redirectToPrizes = () => {
    window.scrollTo(0, 0);

    navigate("/" + appCode + "/gifts-page");
    // campaignServices
    //   .getReceiveGift(data_receive)
    //   .then((res: any) => {
    //     console.log(res);
    //     // setReceiveGift(res);
    //     // setReceiveGiftList(res.gift_list);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     setpopupMessage(e);
    //     setopenPopupMessage(true);
    //   });
  };
  const handleInputNewPhoneName = () => {
    const a = GetCookie("user");
    console.log(a);
    let data = JSON.parse(GetCookie("user"));
    console.log(data);
    const data_receive = {
      params: {
        url: data.params.url,
        session_id: voucher?.session_id,
      },
    };
    console.log(data_receive);
    campaignServices
      .getReceiveGift(data_receive)
      .then((res: any) => {
        console.log(res);
        localStorage.clear();
        navigate("/" + appCode);
        // setReceiveGift(res);
        // setReceiveGiftList(res.gift_list);
      })
      .catch((e) => {
        console.log(e);
        setpopupMessage(e);
        setopenPopupMessage(true);
      });
  };
  const handleNoInputCodeAgain = () => {};
  const handleInputCodeAgain = () => {
    let data = JSON.parse(GetCookie("user"));

    console.log(data);
    data.params["code"] = code;
    data.params["session_id"] = voucher?.session_id;
    console.log(data);
    campaignServices
      .checkPhone_v1(data)
      .then((response: any) => {
        console.log(response);
        localStorage.setItem("voucher_bigc", JSON.stringify(response));
        SetCookie("user", JSON.stringify(data));
        setGift(response);
        setloading(false);
        // navigate("/" + appCode + "/wheelresult");
        setPopupCode(false);
      })
      .catch((err): any => {
        console.log(err);
        setopenPopupMessage(true);
        setpopupMessage(err);
      });
  };
  return (
    <>
      {loading ? (
        <SyncLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <>
          <div
            className={`${
              appCode === "pgtheloc" ? "background-yomost" : "background-tai"
            }`}
          >
            <div style={{ position: "relative", top: 0 }}>
              <img
                src={cpData?.logo_image_header}
                className="image-home"
                style={{ position: "relative", top: 0 }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "25vh",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100%",
                  color: "white",
                }}
              >
                <div
                  className="title-gift"
                  style={{
                    width: "100%",
                    padding: "0 20px",
                    marginTop: "0px",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "30px",
                    fontWeight: "600",
                    color: cpData?.winners_view_color
                  }}
                >
                  XIN CHÚC MỪNG
                </div>{" "}
                <div className="name-gift" style={{color: cpData?.home_text_color}}> Bạn đã trúng phần quà là</div>
                <div className="name-gift" style={{color: `${
                      cpData?.home_url_color
                    }`,}}>{gift?.gift}</div>
                <div className="checksum-gift" style={{color: cpData?.home_text_color}}>{gift?.gift_checksum}</div>
                <div
                  className="img-gift"
                  style={{
                    position: "relative",
                    marginTop: "-20px",
                    marginBottom: "-60px",
                  }}
                >
                  <img
                    src={GIFTS}
                    className=""
                    style={{
                      position: "relative",
                      top: "-60px",
                      width: "100%",
                      left:"50%", transform:"translateX(-50%)",
                      opacity: "1",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "8%",
                      transform: "translate(-50%)",
                    }}
                  >
                    <img
                      src={gift?.gift_image}
                      className=""
                      style={{
                        position: "relative",
                        top: "-30px",
                        width: "250px",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    padding: "0 30px",
                    fontSize: "13px",
                    lineHeight: "19px",
                    position: "relative",
                    top:"-68px",
                    zIndex: 100000,
                  }}
                  className="containerNotify__background"
                >
                  <div className="containerNotify__background-lis" style={{fontSize:"16px",textAlign:"center"}}>
                 <span style={{fontWeight:"700", color: cpData?.home_text_color}}>QUÀ VOUCHER:</span>  <span style={{
                      fontSize:"18px",
                      marginTop:"8px",
                      color: cpData?.home_text_color
                      }}>  Đã được gửi đến tin nhắn của Quý Khách</span>
                  
                  <span
                      style={{
                        color: `${
                          cpData?.home_url_color
                        }`,
                      }}
                      onClick={handleOpen}
                    >
                      {" "}
                      (Xem thêm)
                    </span>
                    {/* )} */}
                    
                  </div>
                </div>
                <div style={{display:"flex", marginTop:"-40px"}}>
                <div
                  style={{
                    position:"relative",
                    zIndex:"999",
                    textAlign: "center",
                    padding: "0 20px",
                    width:"300px"
                  }}
                >
                  <Col>
                    <button
                      className={"btn-register-active"}
                      style={{ height: "53px" }}
                      onClick={onRegister}
                    >
                      NHẬP TIẾP MÃ
                    </button>
                  </Col>
                </div>
                <div
                  style={{
                    
                    textAlign: "center",
                    padding: "0 20px",
                    width:"300px"
                  }}
                >
                  <Col>
                    <div
                      style={{
                        position:"relative",
                        zIndex:"999",
                        height: "53px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "11px",
                        border: "1px solid white",
                        backgroundColor: "#fff",
                      }}
                    >
                      <div
                        className="wheelresult-btn-nhanqua"
                        onClick={() => redirectToPrizes()}
                      >
                        NHẬN QUÀ
                      </div>
                    </div>
                  </Col>
                </div>
                </div>
              </div>
            </div>
            <img src={cpData?.logo_image_footer} className="footer-bottom" />
          </div>
          <Modal show={popupCode} onHide={closePopup}>
            <div className="popup-box-code">
              <img src={BG_POPUP} style={{ width: "100%" }} />
            </div>
            <div className="content-popup-box-code">
              <div>
                <img src={TITLE_POPUP} />
              </div>
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                Vui lòng nhập mã dự thưởng
              </div>
              <label
                style={{
                  marginTop: "30px",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <div style={{ position: "relative", zIndex: "-100000" }}>
                  <img src={INPUT_CUSTOM} />
                  <input
                    id="input-type"
                    type="text"
                    onChange={(e) => onInputCode(e)}
                    style={{
                      position: "absolute",
                      zIndex: "100000",
                      top: "50%",
                      width: "87%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    placeholder="Nhập mã dự thưởng"
                    className="input-hidden"
                  />
                </div>
              </label>
              <div
                style={{
                  display: "flex",
                  marginTop: "120px",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <div>
                  <button
                    className=""
                    style={{
                      width: "120px",
                      height: "50px",
                      borderRadius: "10px",
                      backgroundColor: "#E03022",
                      color: "#fff",
                      border: "3px solid #fff",
                    }}
                    onClick={handleInputNewPhoneName}
                  >
                    SĐT KHÁC
                  </button>
                </div>
                <div
                  style={{
                    marginLeft: "20px",
                  }}
                >
                  <button
                    className=""
                    style={{
                      width: "120px",
                      height: "50px",
                      borderRadius: "10px",
                      backgroundColor: `${code ? "#285C5C" : "#bdbdbd"}`,
                      color: "#fff",
                      border: "3px solid #fff",
                    }}
                    onClick={
                      code ? handleInputCodeAgain : handleNoInputCodeAgain
                    }
                  >
                    NHẬP MÃ
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal show={openPopupMessage}>
            <div className="popup-box">
              <>
                <img
                  className="close-btn"
                  src={close}
                  onClick={closePopupGiftError}
                />
                <div className="popup-message">
                  <h3 className="popup-header">Thông báo</h3>
                  <p>{popupMessage}</p>
                </div>
                <button className="popup-btn-ok" onClick={closePopupGiftError}>
                  Đồng ý
                </button>
              </>
            </div>
          </Modal>
          <Modal show={seeMore} onHide={closePopup}>
            <div className="popup-box-code">
              <img src={BG_POPUP} style={{ width: "100%" }} />
              <div>
              <img src={TITLE} style={{position:"absolute", top:"20px", left:"50%", transform:"translateX(-50%)"}}/>
            </div>
            </div>
            
            <div className="containerNotify__background content-popup-box-content">
              <div className="containerNotify__background-list reponsive-padding" style={{marginTop:"80px", textAlign:"justify", fontSize:"14px"}}>
              <ul >
                <li>
                <span style={{fontWeight:"800"}}>QUÀ VẬT LÝ:</span> Hotline 028 362 22 363 sẽ liên hệ để thu thập thông tin trao giải trong 1-3 ngày tới<br/> 
                </li>
                <li>
                <span style={{fontWeight:"800"}}>QUÀ VOUCHER:</span> Đã được gửi đến tin nhắn của Quý Khách
                </li>
              </ul>
              <div style={{textAlign:"center", fontSize:"18px", fontWeight:"800", padding:"0 10px"}}>
              Vui lòng nhận cuộc gọi và giữ lại tin nhắn HOẶC màn hình trúng giải
              </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                left: "50%",
                top: "30px",
                marginLeft: "-15px",
              }}
              onClick={closePopup}
            >
              <img
                src={CLOSE_WR}
                style={{
                  width: "40px",
                }}
              />
            </div>
          </Modal>
          <Social />
        </>
      )}
    </>
  );
};

export default Home;

import "../assets/css/wheelresult.css";
import INTRO_2 from "../assets/images/intro-first.png";
import FOOTER_1 from "../assets/images/footer-1.png";
import { useEffect, useState } from "react";
import "../assets/css/wheelresult.css";
import { Col, Container, Modal, Row } from "react-bootstrap";
import GIFT_BG from "../assets/images/background-gift.png";
import GIFT_RESULT from "../assets/images/gift-result.png";
import { useNavigate } from "react-router-dom";
import { CAMPAIGN_APPCODE, CAMPAIGN_INFO } from "../constants/KeyConstant";
import { crudApi } from "../api/crudApi";
import { campaignServices } from "../api/Services/CampaignService";
import GetCookie from "../hooks/GetCookie";
import close from "../assets/images/close.png";
import BG_GOLD from "../assets/images/gold.png";
import axios from "axios";
import Social from "../components/Social";
const GiftPage = () => {
  const navigate = useNavigate();
  let cpData: any = JSON.parse(localStorage.getItem(CAMPAIGN_INFO) || "{}");
  const appCode = localStorage.getItem(CAMPAIGN_APPCODE);
  let voucher: any = JSON.parse(localStorage.getItem("voucher_bigc") || "{}");
  const onRegister = () => {
    navigate("/" + appCode);
    localStorage.clear();
  };
  const [receiveGift, setReceiveGift] = useState<any>();
  const [receiveGiftList, setReceiveGiftList] = useState<any>();
  const [openPopupMessage, setopenPopupMessage] = useState<boolean>(false);
  const [popupMessage, setpopupMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    const a = GetCookie("user");
    console.log(a);
    let data = JSON.parse(GetCookie("user"));
    console.log(data);
    const data_receive = {
      params: {
        url: data.params.url,
        session_id: voucher?.session_id,
      },
    };
    console.log(data_receive);
    campaignServices
      .getReceiveGift(data_receive)
      .then((res: any) => {
        console.log(res);
        setReceiveGift(res);
        setReceiveGiftList(res.gift_list);
      })
      .catch((e) => {
        console.log(e);
        setpopupMessage(e);
        setopenPopupMessage(true);
      });
    // getIP();
    // localStorage.clear();
  }, []);
  const getIP = async () => {
    const a = await axios.get(
      "https://ipgeolocation.abstractapi.com/v1/?api_key=10c186258733484e812b16506581652d"
    );
    if (a?.data?.country === "Vietnam") {
    } else {
      navigate("/page403");
    }
  };
  const closePopup = () => {
    setopenPopupMessage(false);
    navigate("/" + appCode);
  };
  return (
    <>
      <div
        className={`${
          appCode === "pgtheloc" ? "background-yomost" : "background-tai"
        }`}
      >
        <div style={{ position: "relative", top: 0 }}>
          <img
            src={cpData?.logo_image_header}
            className="image-home"
            style={{ position: "relative", top: 0 }}
          />
          <div
            style={{
              position: "absolute",
              top: "25vh",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              color: "white",
            }}
          >
            <div
              style={{
                width: "90%",
                position: "relative",
                transform: "translateX(-50%)",
                top: "10px",
                left: "50%",
              }}
            >
              <img
                src={GIFT_BG}
                style={{
                  width: "100%",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                transform: "translateX(-50%)",
                width: "280px",
                left: "50%",
                color: "black",
              }}
              className="top-title-gift"
            >
              Sau <span className="number-titlegift">{receiveGift?.count}</span>{" "}
              lượt mở mã dự thưởng Quý Khách nhận được
            </div>
            <div
              style={{
                position: "absolute",
                transform: "translateX(-50%)",
                top: "140px",
                left: "50%",
                width: "90%",
                maxWidth: "430px",
              }}
              className="grid-gift"
            >
              <div className="grid-gift-list">
                <Row xs={12}>
                  {receiveGiftList?.map((item: any, index: any) => (
                    <Col xs={4} style={{ marginTop: "10px" }} key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div style={{position:"relative"}}>
                          <img src={BG_GOLD}/>
                          <img
                            src={item?.gift_image}
                            style={{position:"absolute", left:"0", top:"50%", transform:"translateY(-50%)", width: "80px", height: "60px" }}
                          />
                        </div>
                        <div
                          className="length-cha"
                          style={{ width: "100px", color: "black" }}
                        >
                          <span className="number-titlegift">
                            {item?.gift_no}
                          </span>{" "}
                          {item?.gift}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              <Row
                style={{
                  marginTop: "40px",
                  textAlign: "center",
                  padding: "0 40px",
                }}
              >
                <Col>
                  <button
                    className={"btn-register-active"}
                    style={{ height: "53px" }}
                    onClick={onRegister}
                  >
                    QUAY VỀ TRANG CHỦ
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <img src={cpData?.logo_image_footer} className="footer-bottom" />

        <Modal show={openPopupMessage}>
          <div className="popup-box">
            <>
              <img className="close-btn" src={close} onClick={closePopup} />
              <div className="popup-message">
                <h3 className="popup-header">Thông báo</h3>
                <p>{popupMessage}</p>
              </div>
              <button className="popup-btn-ok" onClick={closePopup}>
                Đồng ý
              </button>
            </>
          </div>
        </Modal>
      </div>
      <Social />
    </>
  );
};

export default GiftPage;
